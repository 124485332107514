import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Page two" mdxType="SEO" />
    <h1>{`Hi from the second page`}</h1>
    <p>{`Welcome to page 2`}</p>
    <Link to="/" mdxType="Link">Go back to the homepage</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      